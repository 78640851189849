<template>
	<div class="app-container disN">
	<div class="main">
		<el-header class="titles">分组管理<el-button type="primary" size="mini"  @click='loadTree' style="margin-left:15px;">刷新</el-button></el-header>
		<div class="sortContent">
			<div class="left">
				<div class="demo-input-suffix">
					类型:
					<el-select v-model="type" placeholder="请选择" clearable filterable @input='loadTree'>
						<el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<!-- 状态:
					<el-select v-model="isDelete" filterable @input='loadTree' style="margin-top: 10px;">
						<el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
				</div>
				<el-row style="text-align: center;">
					<el-button type="success" size="mini" v-show="isDelete == 0" @click='changeState(0)'>添加</el-button>
					<el-button type="primary" size="mini" v-show="this.currentCate.id && isDelete == 0" @click='changeState(1)'>编辑</el-button>
					<el-button type="danger" size="mini" v-show="isDelete == 0 && this.currentCate.id" @click='forbidsort'>删除</el-button>
					<!-- <el-button type="success" size="mini" v-show="currentCate.pid == '0'" @click='importFenzu' >导出至分组</el-button> -->
				</el-row>
				<div style="height: calc(100vh - 340px);">
					<el-scrollbar style='height: 100%;'>
						<el-tree :data="tree" :props="defaultProps" @node-click="selcetOpt" :default-expand-all='true' draggable>
						</el-tree>
					</el-scrollbar>
				</div>

			</div>
			<div class="right" v-loading ='loading'>
				<el-transfer v-show="allListStandard.length >= 0" :button-texts="['删除', '添加']" @change="dataMove" v-model="indexArr"
				 :data="allListStandard" filterable :filter-method="filterMethod" filter-placeholder="请输入书本名字" :titles="formTitles"
				 >
					<span slot-scope="{ option }" class="bookShow"><img :src="$comjs.imgPd(option.picUrl)" class="headImg" @error="$comjs.reload"><br><span
						 class="goodsName">{{option.name}}</span></span>
					<div slot="left-footer">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
						 :page-sizes="pageSizeArr" :pager-count="5" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="total" >
						</el-pagination>
					</div>
					<div slot="right-footer">
						<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="page2"
						 :page-sizes="pageSizeArr2" :pager-count="5" :page-size="pageSize2" layout="total, sizes, prev, pager, next, jumper"
						 :total="total2" >
						</el-pagination>
					</div>
				</el-transfer>
			</div>
			<el-dialog :title="dialogTitie" :visible.sync="dialogFormVisible" :close-on-click-modal='false'>
				<el-form :model="sortForm">
					<el-form-item label="分组名称" :label-width="formLabelWidth">
						<el-input v-model="sortForm.name" autocomplete="off"></el-input><span class="must">*</span>
					</el-form-item>
					<el-form-item label="父分类" :label-width="formLabelWidth" v-show="changeTypeState == 0">
						<el-input v-model="pidName" autocomplete="off" :disabled="true"></el-input><span class="must">*</span>
					</el-form-item>
					<el-form-item label="类型" :label-width="formLabelWidth">
						<el-select v-model="sortForm.type" placeholder="请选择类型">
							<el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
						<span class="must">*</span>
					</el-form-item>
					<el-form-item label="序号" :label-width="formLabelWidth">
						<el-input v-model="sortForm.sort" autocomplete="off" @keyup.native='$comjs.inputNum($event,sortForm.sort)'></el-input><span
						 class="must">*</span>
					</el-form-item>
					<el-tree v-loading="loadingTree" :data="treeS" :props="defaultProps" node-key="id" ref="treeS" :default-expand-all='true' show-checkbox>
					</el-tree>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" @click="saveState" :loading="isSureLoading">保存</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
	</div>
</template>

<script>
	import $ from '../../../plugins/jquery-1.11.1.min.js'
	export default {
		name: 'classify',
		components: {
			// Name
		},
		data() {
			return {
				loadingTree: false,
				suosouFlag:false,
				loading: false,
				dialogTitie: '新增分组',
				changeTypeState: '',
				sortState: '',
				tree: [{
					name: '分组管理',
					childrens: [],
					pid: '',
					id: ''
				}],
				treeS: [{
					name: '分类管理',
					childrens: [],
					pid: '',
					id: ''
				}],
				currentCate: {},
				test: [],
				type: '',
				pidName: '',
				sortForm: {
					name: '',
					type: 2,
					pid: '',
					sort: 99,
				},
				isSureLoading: false,
				formLabelWidth: '120px',
				isDelete: 0,
				total: 0,
				total2: 0,
				dialogFormVisible: false,
				pageSizeArr: [10, 20, 30, 50],
				pageSizeArr2: [10, 20, 30, 50],
				sortOptions: [{
					label: '年级',
					value: 1,
				}, {
					label: '品种',
					value: 2,
				}, {
					label: '出版社',
					value: 3,
				}],
				defaultProps: {
					children: 'childrens',
					label: 'name'
				},
				stateOptions: [{
						label: '启用中',
						value: 0,
					},
					{
						label: '禁用中',
						value: 1,
					}
				],
				allListStandard: [],
				notContainData: [],
				containData: [],
				notCluList: [],
				indexArr: [],
				index: 0,
				page: 1,
				pageSize: 10,
				page2: 1,
				pageSize2: 10,
				sortFilter: [{
					col: 'type',
					type: '=',
					val: ''
				}],
				filter: [{
					col: 'goodsName',
					type: 'like',
					val: ''
				}],
				filter2: [{
					col: 'goodsName',
					type: 'like',
					val: ''
				}],
				categoryId: '',
				formTitles: [],
				filterMethod(query, item) {
					// console.log(query)
					return true;
				},
				clearFlag :false
			}
		},
		created() {
			this.loadTree();
		},
		mounted() {
			$('input[type="text"]').on('compositionstart', () => {
				this.suosouFlag = true;
			})
			$('input[type="text"]').on('compositionend',(e) => {
				this.suosouFlag = false;
				this.find(e)
				// mescroll.resetUpScroll();
			})
			$('input[type="text"]').on('input',(e)=>{
				if (!this.suosouFlag) {
					this.find(e)
				}
			})
			var _this = this;
			$('span.el-input__prefix').hover(function () {
				// alert(11)
				if($(this).find('i').is('.el-icon-circle-close')){
					_this.clearFlag = true 
				}
			})
			$('span.el-input__prefix').on('click',function () {
				if(_this.clearFlag){
					if ($(this).parents('.el-transfer-panel').index() == 0) {
						// debugger
						_this.filter[0].val = '';
						_this.loadnotContainData();
					} else {
						_this.filter2[0].val = '';
						_this.loadcontainData();
					}
					_this.clearFlag = false
				}
				
			})
		},
		methods: {
			// importFenzu(){
			// 	this.$comjs.ajax.postAjax('/g/admin/bookType/addCategoryType',{categoryId:this.currentCate.code},(res)=>{
			// 		this.$message({
			// 			showClose: true,
			// 			type: 'success',
			// 			message: res.message
			// 		});
			// 	})
			// },
			loadTree() {
				var data = {
					type: this.type
				}
				this.currentCate = '';
				this.$comjs.ajax.getAjax('/fa/exam/goods/group/query', data, this, (res) => {
					this.tree[0].childrens = this.$comjs.getTree(res.list);
					console.log(this.tree[0].childrens)
					// console.log(this.tree)
				}, function(res) {
					// console.log(res)
				})
			},
			loadTreeS(value) {
				this.loadingTree = true;
				var data = {
					type: this.type
				}
				this.$comjs.ajax.getAjax('/fa/exam/goods/type/query', data, this, (res) => {
					this.treeS[0].childrens = this.$comjs.getTree(res.list);
					if(value == 1) {
						let arr = [];
						this.currentCate.typeList.forEach(val => {
							arr.push(val.id);
						})
						this.$nextTick(function() {
						    let timeKeys = setTimeout(() => {
								this.$refs.treeS.setCheckedKeys(arr);
								clearTimeout(timeKeys);
								this.loadingTree = false;
							}, 300);
						})
						this.sortForm.typeList = arr;
					} else {
						this.loadingTree = false;
					}
				}, function(res) {
					this.loadingTree = false;
					// console.log(res)
				})
			},
			selcetOpt(obj) {
				// console.log(obj)
				this.currentCate = obj;
				this.categoryId = obj.id;
				this.formTitles = ['书库', obj.name]
				if (this.categoryId) {
					this.initformData()
				} else {
					this.allListStandard = [];
				}
			},
			initformData() {
				this.loadnotContainData('init');
				this.loading = true;
				// console.log(new Date().getTime())
			},
			reorData() {
				this.index = 0;
				this.allListStandard = [];
				this.indexArr = [];
				for (var i in this.containData) {
					var data = this.containData[i];
					this.allListStandard.push({
						key: i * 1 + 1,
						...data
					})
					this.indexArr.push(i * 1 + 1);
				}
				this.index = this.containData.length;
				// console.log(this.notContainData)
				for (var j in this.notContainData) {
					var data = this.notContainData[j];
					this.allListStandard.push({
						key: this.index + j * 1 + 1,
						...data
					})
				}
				this.loading = false
				// console.log(this.allListStandard)

			},
			loadnotContainData(init) {
				// this.loading = true
				// var data = {
				// 	groupId: this.currentCate.id,
				// 	existType: 0,
				// 	page: this.page,
				// 	pageSize: this.pageSize,
				// 	name: this.filter[0].val
				// }
				// this.$comjs.ajax.getAjax('/fa/exam/goods/query', data, this, (res) => {
				// 	this.notContainData = res.list;
				// 	this.total = res.total;
				// 	if (init) {
						this.loadcontainData('init');
				// 	} else {
				// 		this.reorData();
				// 	}
				// }, function(res) {
				// 	// console.log(res)
				// })
			},
			loadcontainData() {
				this.loading = true
				var data = {
					groupId: this.currentCate.id,
					existType: 1,
					status: 1,
					page: this.page2,
					pageSize: this.pageSize2,
					name: this.filter2[0].val
				}
				console.log(this.pageSize2)
				this.$comjs.ajax.getAjax('/fa/exam/goods/query', data, this, (res) => {
					this.containData = res.list;
					this.total2 = res.total;
					this.reorData();

				}, function(res) {
					// console.log(res)
				})
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.loadnotContainData('init');
			},
			handleCurrentChange(val) {
				this.page = val
				this.loadnotContainData('init');
			},
			handleSizeChange2(val) {
				this.pageSize2 = val
				this.loadnotContainData('init');
			},
			handleCurrentChange2(val) {
				this.page2 = val
				this.loadnotContainData('init');
			},
			find(e) {
				// console.log(e)
				if($(e.target).attr('type') == 'text'){
					if ($(e.target).parents('.el-transfer-panel').index() == 0) {
						// debugger
						this.filter[0].val = e.target.value;
						this.loadnotContainData();
					} else {
						this.filter2[0].val = e.target.value;
						this.loadcontainData();
					}
				}
				// return item.label.indexOf(query) > -1;
			},
			dataMove(value, direction, movedKeys) {
			// 	console.log(value, direction, movedKeys);
			// 	let goodsIds = [];
			// 	for (var i in movedKeys) {
			// 		var index = movedKeys[i] - 1;
			// 		var id = this.allListStandard[index].id;
			// 		goodsIds.push(id);
			// 	}
			// 	let data = {
			// 		typeId: this.categoryId,
			// 		goodsIds
			// 	}
			// 	if (direction == 'right') {
			// 		this.$comjs.ajax.postJsonAjax('/fa/exam/goods/type/add-goods', data, this, (res) => {
			// 			this.$message({
			// 				showClose: true,
			// 				type: 'success',
			// 				message: res.message
			// 			});
			// 			if (this.categoryId) {
			// 				this.initformData()
			// 			} else {
			// 				this.allListStandard = [];
			// 			}
			// 		})
			// 	} else {
			// 		this.$comjs.ajax.postJsonAjax('/fa/exam/goods/type/remove-goods', data, this, (res) => {
			// 			this.$message({
			// 				showClose: true,
			// 				type: 'success',
			// 				message: res.message
			// 			});
			// 			if (this.categoryId) {
			// 				this.initformData()
			// 			} else {
			// 				this.allListStandard = [];
			// 			}
			// 		})
			// 	}
			},
			saveState() {
				// console.log(this.sortForm.type);
				if (!this.sortForm.name) {
					this.$message({
						showClose: true,
						message: '请填写分类名称',
						type: 'warning'
					})
					return;
				}
				if (!this.sortForm.type) {
					this.$message({
						showClose: true,
						message: '请选择分类类型',
						type: 'warning'
					})
					return;
				}
				if (!this.sortForm.sort) {
					this.$message({
						showClose: true,
						message: '请选择分类序号',
						type: 'warning'
					})
					return;
				}
				if (this.changeTypeState == 0) {
					// this.sortForm.typeIdList = this.$refs.treeS.getCheckedKeys();
					var datas = {
						typeIdList: this.$refs.treeS.getCheckedKeys(),
						id: this.currentCate.id,
						...this.sortForm
					};
					this.$comjs.ajax.postJsonAjax('/fa/exam/goods/group/add', datas, this, (res) => {
						this.$message({
							showClose: true,
							type: 'success',
							message: res.message
						});
						this.loadTree();
						this.dialogFormVisible = false;
					})
				} else {
					var data = {
						typeIdList: this.$refs.treeS.getCheckedKeys(),
						id: this.currentCate.id,
						...this.sortForm
					};
					data.pid = '123';
					this.$comjs.ajax.postJsonAjax('/fa/exam/goods/group/edit', data, this, (res) => {
						this.$message({
							showClose: true,
							type: 'success',
							message: res.message
						});
						this.loadTree();
						this.dialogFormVisible = false;
					})
				}

			},
			changeState(value) {
				let _this = this;
				this.changeTypeState = value;
				this.loadTreeS(value);
				if (value == 0) {
					// 添加分类
					if (this.currentCate.id) {
						this.sortForm.pid = this.currentCate.id;
						this.pidName = this.currentCate.name;
					} else {
						this.sortForm.pid = 0
						this.pidName = '顶级分类'
					}
					this.sortForm.name = '';
					this.sortForm.type = 1;
					this.sortForm.sort = 99;
				} else {
					// 编辑分类
					// this.sortForm.pid = this.currentCate.pid;
					// this.pidName = this.currentCate.name;
					this.sortForm.pid = '';
					this.sortForm.name = this.currentCate.name;
					this.sortForm.type = parseInt(this.currentCate.type);
					this.sortForm.sort = this.currentCate.sort;
				}
				this.dialogFormVisible = true;
			},
			forbidsort() {
				this.$alert('是否确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					// var data = {
					// 	id: this.currentCate.id
					// }
					this.$comjs.ajax.postJsonAjax('/fa/exam/goods/group/del', [this.currentCate.id], this, (res) => {
						this.$message({
							showClose: true,
							type: 'success',
							message: res.message
						});
						this.loadTree();
						this.dialogFormVisible = false;
					})
				}).catch(() => {});

			},
			resetsort() {
				var data = {
					id: this.currentCate.id
				}
				this.$comjs.ajax.postAjax('/g/admin/category/active', data, (res) => {
					this.$message({
						showClose: true,
						type: 'success',
						message: res.message
					});
					this.loadTree();
					this.dialogFormVisible = false;
				})
			}
		},
	}
</script>
<style type="text/css">
	.el-tree-node:focus>.el-tree-node__content, .el-tree-node__content:hover, .el-tree .is-current {
	    background-color: #f1f5ff;
	}
	.main .el-transfer-panel {
		width: 500px;
		height: calc(100vh - 225px);
	}
	.main .el-transfer__button {
		display: block;
	}
	.main .el-transfer__button:nth-child(2) {
		margin: 0;
	}
	.el-button [class*=el-icon-]+span {
		margin-left: 0;
	}
	.main .el-transfer__buttons {
	    padding: 0 10px;
	}
	label.el-checkbox.el-transfer-panel__item {
		display: inline-block !important;
		margin-right: 0;
	}
	.disN .el-transfer .el-transfer-panel:first-child, .disN .el-transfer .el-transfer__buttons {
		display: none !important;
	}
	.main .el-transfer-panel__body {
	    width: auto;
	    height: calc(100% - 90px);
	    overflow: scroll;
	}
</style>
<style scoped="scoped">
	span {
	    display: inline-block;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.app-container {
		overflow: auto;
		height: calc(100vh - 126px);
		padding: 20px 20px 0;
	}
	/* 搜索框样式 */
	>>>.el-transfer-panel .el-transfer-panel__footer{
		height: 50px;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 20px;
	}
	>>>.el-transfer-panel__list.is-filterable{
		height: auto !important;
	}
	>>>.el-transfer-panel__filter{
		position: sticky;
		top: 0;
		z-index: 20;
	}
	.el-button{
		margin-bottom:10px;
	}
	.demo-input-suffix {
		margin-top: 5px;
		margin-bottom: 0;
		background-color: white;
		padding: 10px;
		border-radius: 5px;
	}

	.main {
		/* min-width: 1350px; */
		overflow: hidden;
	}

	.sortContent {
		display: flex;
		margin-top: 20px;
	}

	.left {
		width: 20%;
		background-color: white;
		min-width: 200px;
	}

	.right {
		width: 75%;
		margin-left: 1%;
		min-width: 1150px;
	}

	.el-tree {
		margin: 10px 0;
	}

	.el-transfer {
		width: 100%;
		min-width: 250px;
	}

	.headImg {
		height: 90px;
		width: 80px;
	}

	.goodsName {
		width: 120px;
		white-space: normal;
	    line-height: 18px;	
	}
	/* .bookShow{
		display: flex;
	} */
	/* >>>.main .el-checkbox__label{
		height: auto !important;
	} */

	.el-pagination {
		margin-top: 5px;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-row {
		padding: 5px;
	}

	.el-dialog .el-input {
		width: 70%;
	}

	.left .el-select {
		width: 70%;
	}
</style>
